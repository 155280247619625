<template>
  <div>
    <v-select
      :options="filterActiveUsers"
      label="name"
      :clearable="false"
      v-model="selected"
    >
      <template v-slot:option="option">
        <div class="d-flex">
          <img
            :src="option.profile_pic_url"
            v-if="!option.disabled"
            alt=""
            height="32px"
            width="32px"
            class="rounded-circle me-2"
          />
          <span class="truncate">{{ option.name }}</span>
        </div>
      </template>
      <template v-if="loading" #selected-option="{ profile_pic_url, name }">
        <i class="far fa-spin fa-spinner me-2" />
        Loading...
      </template>
      <template v-else #selected-option="{ profile_pic_url, name }">
        <img
          :src="profile_pic_url"
          v-if="profile_pic_url"
          alt=""
          height="23px"
          width="23px"
          class="rounded-circle me-2"
        />
        <i v-else class="far fa-user text-primary me-2" />
        <span class="truncate">{{ acronym(name) }}</span>
      </template>
      <template #no-options="{ search, searching, loading }">
        No users available.
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  props: ["value", "predefinedUsers"],
  data() {
    return {
      loading: true,
      users: [],
      selected: this.value,
    };
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected() {
      if (this.selected && !this.selected.disabled) {
        this.$emit("input", this.selected);
      }
    },
    predefinedUsers() {
      this.users = this.predefinedUsers;
    },
  },
  methods: {
    acronym(name) {
      var str = name;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      var acronym = matches.join(""); // JSON

      return acronym;
    },
    isSelected(el) {
      if (this.selectedElements && this.selectedElements.length) {
        return this.selectedElements.lastIndexOf(el.id) > -1;
      }
      return this.selected && this.selected.id === el.id;
    },
    fetchUsers() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/clinics/users")
        .then(({ data }) => {
          this.users = data;
          this.loading = false;
        });
    },
  },
  mounted() {
    if (!this.predefinedUsers) {
      this.fetchUsers();
    } else {
      this.users = this.predefinedUsers;
      this.loading = false;
    }
  },
  computed: {
    filterActiveUsers() {
      return this.users.filter((user) => {
        return user.status === "active";
      });
    },
  },
  components: {
    vSelect,
  },
};
</script>
<style lang="scss">
.v-select {
  background: #ffffff;
}

.v-select {
  min-width: 150px;
}

.v-select .vs__dropdown-menu {
  min-width: 300px;
}
</style>
