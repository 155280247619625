var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"options":_vm.filterActiveUsers,"label":"name","clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex"},[(!option.disabled)?_c('img',{staticClass:"rounded-circle me-2",attrs:{"src":option.profile_pic_url,"alt":"","height":"32px","width":"32px"}}):_vm._e(),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(option.name))])])]}},(_vm.loading)?{key:"selected-option",fn:function(ref){
var profile_pic_url = ref.profile_pic_url;
var name = ref.name;
return [_c('i',{staticClass:"far fa-spin fa-spinner me-2"}),_vm._v(" Loading... ")]}}:{key:"selected-option",fn:function(ref){
var profile_pic_url = ref.profile_pic_url;
var name = ref.name;
return [(profile_pic_url)?_c('img',{staticClass:"rounded-circle me-2",attrs:{"src":profile_pic_url,"alt":"","height":"23px","width":"23px"}}):_c('i',{staticClass:"far fa-user text-primary me-2"}),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.acronym(name)))])]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" No users available. ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }